<script>
    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SvelteMarkdown from 'svelte-markdown';

    const termsMarkdown = `
# Terms and Conditions

Welcome to BuyMyNudes! These Terms and Conditions outline the rules and regulations for the use of BuyMyNudes's Website, located at https://buymynudes.com.

## 1. Terms

By accessing this website, you agree to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. You must be at least 18 years old to use this service.

## 2. Use License and Content Downloads

a) Viewing Content: Permission is granted to temporarily access and view the content on BuyMyNudes's website for personal, non-commercial use only.

b) Downloadable Content: Some content on BuyMyNudes may be available for download. By downloading any content, you agree to the following:

- You may download content only for personal, non-commercial use.
- Downloading does not transfer ownership or intellectual property rights to you.
- You may not distribute, share, or republish downloaded content in any form.
- You may not use downloaded content for any commercial purposes.
- You are responsible for maintaining the confidentiality of your downloads.

This license shall automatically terminate if you violate any of these restrictions and may be terminated by BuyMyNudes at any time.

## 3. User Accounts and Content

a) Account Creation: To access certain features of the site, you must create an account. You are responsible for maintaining the confidentiality of your account and password.

b) User-Generated Content: If you create or upload any content to BuyMyNudes, you retain your intellectual property rights, but grant BuyMyNudes a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute that content on our platform.

c) Content Restrictions: You agree not to upload, share, or create any content that is illegal, abusive, or violates the rights of others. BuyMyNudes reserves the right to remove any content that violates these terms.

d) Content Review Process: All user-uploaded content undergoes a mandatory review process before publication. This process includes:

   1. Automated Screening: Content is initially screened using automated systems, including face detection and matching technology.
   2. Creator Verification: All faces detected in content must match verified creators in our database.
   3. Multiple Participants: If content includes multiple participants, all must be verified creators on our platform.
   4. Manual Review: Content that doesn't pass automated screening is subject to manual review.
   5. AI Detection: We employ technology to detect AI-generated content, which may be subject to additional review or restrictions.
   6. Real-Time Monitoring: Content may be flagged for review based on user reports, with potentially problematic content being temporarily hidden pending review.

e) Content Removal and Account Actions: BuyMyNudes reserves the right to remove content, issue strikes against accounts, or ban accounts that violate our terms. Severe violations may be reported to legal authorities.

f) Reporting Content: Users are encouraged to report content that violates our terms. Reports are reviewed by our team, and appropriate action will be taken based on the severity of the violation.

## 4. Age Verification and Consent

BuyMyNudes is an adult platform. By using this site, you confirm that:

- You are at least 18 years old or the legal age of majority in your jurisdiction.
- You consent to view adult-oriented material.
- You will not allow any minor to access this site through your account.

## 5. Privacy and Data Protection

Your use of BuyMyNudes is also governed by our Privacy Policy. Please review our Privacy Policy to understand our practices.

## 6. Payment and Subscriptions

a) Fees: Some content on BuyMyNudes may require payment. All fees are non-refundable unless otherwise stated.

b) Subscriptions: If you purchase a subscription, it will automatically renew unless canceled. You can cancel at any time through your account settings.

## 7. Disclaimer

The materials and content on BuyMyNudes's website are provided on an 'as is' basis. BuyMyNudes makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.

## 8. Limitations of Liability

In no event shall BuyMyNudes or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials or services on BuyMyNudes's website, even if BuyMyNudes or a BuyMyNudes authorized representative has been notified orally or in writing of the possibility of such damage.

## 9. Content Accuracy and Changes

The materials appearing on BuyMyNudes's website could include technical, typographical, or photographic errors. BuyMyNudes does not warrant that any of the materials on its website are accurate, complete or current. BuyMyNudes may make changes to the materials contained on its website at any time without notice.

## 10. Third-Party Links

BuyMyNudes may contain links to third-party websites. These links are provided for convenience only and do not imply endorsement. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.

## 11. Termination

We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.

## 12. Governing Law

These terms and conditions are governed by and construed in accordance with the laws of Germany, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.

## 13. Changes to Terms

BuyMyNudes reserves the right to modify these terms of service at any time. We will notify users of any significant changes. Your continued use of the site after changes to the Terms constitutes your acceptance of the new Terms.

## 14. Contact Information

If you have any questions about these Terms, please contact us at [legal@buymynudes.com](mailto:legal@buymynudes.com).

By using BuyMyNudes, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.
`;
</script>

<Header />
<main class="prose mx-auto flex max-w-7xl flex-col px-4 py-8 text-black dark:text-white sm:px-6 lg:px-8">
    <SvelteMarkdown source="{termsMarkdown}" />
</main>
<Footer />
